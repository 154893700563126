import { track } from "jiffy-analytics";

(function () {
  function onTidioChatApiReady() {
    // Track chat status changes
    window.tidioChatApi.on("setStatus", status => {
      track("tidio_chat_user_event", { action: "setStatus", status });
    });

    // Track messages from visitors
    window.tidioChatApi.on("messageFromVisitor", message => {
      track("tidio_chat_user_event", { action: "messageFromVisitor", message });
    });

    // Track operator messages
    window.tidioChatApi.on("messageFromOperator", message => {
      track("tidio_chat_user_event", { action: "messageFromOperator", message });
    });

    // Track chat widget open and close
    window.tidioChatApi.on("open", () => {
      track("tidio_chat_user_event", { action: "open" });
    });

    window.tidioChatApi.on("close", () => {
      track("tidio_chat_user_event", { action: "close" });
    });

    // Track conversation start
    window.tidioChatApi.on("conversationStart", () => {
      track("tidio_chat_user_event", { action: "conversationStart" });
    });

    // Track conversation start
    window.tidioChatApi.on("preFormFilled", data => {
      track("tidio_chat_user_event", { action: "preFormFilled", data });
    });
  }

  if (window.tidioChatApi) {
    window.tidioChatApi.on("ready", onTidioChatApiReady);
  } else {
    document.addEventListener("tidioChat-ready", onTidioChatApiReady);
  }
})();
